const DataDownloadPage = () => {
  return (
    <div className="about">
      <h2>Download a local copy of the site data</h2>
      <hr />
      <br />
      <p>
        The purpose of this site truly is to start a conversation. To that end,
        we believe the data should be made as accessible as possible, in order
        to both spread the message further, but also to ensure the data is
        secured by disseminating copies as widely as possible.
        <br />
        <br />
        There are two ways to download the data:
        <br />
        <br />
        <ol>
          <li>
            <b>RECOMMENDED IF ABLE: </b>Download the data from SubscribeStar as
            a member -
            <ul>
              <li>
                The best way to ensure you have access to the latest data is to
                sign up for my{" "}
                <a href="https://www.subscribestar.com/leather-apron-club">
                  Subscribe Star
                </a>
                . All Subscribe Star supporters will have access to a backup of
                all Individuals on Social Sentinel in a downloadable CSV.
                Updated automatically every week. Running this site isn't free
                and development takes time, so we would appreciate any support
                users can provide.
              </li>
            </ul>
          </li>
          <br />
          <li>
            Download the data from a public torrent -
            <ul>
              <li>
                Full site data will also be periodically shared via torrent.
                This is completely free to download, but it may not be as up to
                date as the data available on Subscribe Star. If you use this
                option, I simply ask that you seed the torrent for as long as
                possible, and consider donating to help support the site in any
                way you can.
              </li>
              <br />
              <ul>
                <li>
                  <b>10/19/2024</b> <a href="/">TORRENT LINK HERE</a>
                </li>
              </ul>
            </ul>
          </li>
        </ol>
      </p>
    </div>
  );
};

export default DataDownloadPage;
