import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import { confirmable, createConfirmation } from "react-confirm";

const ConfirmationDialog = ({
  okLabel = "OK",
  cancelLabel = "Cancel",
  confirmation,
  show,
  proceed,
  enableEscape = true,
}) => {
  return (
    <div className="static-modal">
      <Modal
        className="confirmation-modal"
        animation={false}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : "static"}
        keyboard={enableEscape}
      >
        <Modal.Body className="confirmation-modal-body">{confirmation}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            type="submit"
            onClick={() => proceed(false)}
          >
            {cancelLabel}
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={() => proceed(true)}
          >
            {okLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ConfirmationDialog.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function confirm(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "cancel",
  options = {}
) {
  return createConfirmation(confirmable(ConfirmationDialog))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}
