import { useState, useEffect } from "react";
import { getIndividualUnderscored, getBaseUrl } from "../../helpers";
import { useNavigate } from "react-router-dom";
import axios from "../../app/api/axios";
import avatarPlaceholder from "../../res/avatar-placeholder.png";

const INDIVIDUAL_IMAGE_URL = "/individual_images/";

const SUMMARY_URL = "/individualSummary/";

const IndividualSummary = (props) => {
  const [individual, setIndividual] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(getIndividualUnderscored(props.name));
  };

  const getImageSrc = () => {
    if (!individual) {
      return avatarPlaceholder;
    } else if (individual.image_filename) {
      return getBaseUrl() + INDIVIDUAL_IMAGE_URL + individual.image_filename;
    } else {
      return individual.individual_image_url;
    }
  };


  useEffect(() => {
    const getIndividual = async () => {
      const { data } = await axios.get(SUMMARY_URL + props.name);

      setIndividual(data[0]);
    };
    getIndividual();
  }, [props.name]);

  return (
    <div className="individual-summary">
      {!individual && (
        <img
          src="https://via.placeholder.com/300"
          className="individual-summary-image"
          alt="individual"
        />
      )}
      {individual && (
        <div onClick={handleClick}>
          <img
            src={getImageSrc()}
            className="individual-summary-image"
            alt="individual"
          />
          <h2>{props.name}</h2>
          {individual.ethnicity && (
            <p>
              <b>ETHNICITY:</b> {individual.ethnicity}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default IndividualSummary;
