import {
  MemeGenerator,
  About,
  SupportUs,
  DataDownloadPage,
  Organization,
  Navbar,
  NavSidebar,
  Individual,
  IndividualEditForm,
  IndividualSubmissionForm,
  ProfilePage,
  NotFound,
} from "./components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Home";

const RoutesComponent = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="below-navbar">
          <NavSidebar />
          <div className="content">
            <Routes>
              {/* <Route element={<PersistLogin />}> */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route
                path="//organization/:orgName"
                element={<Organization />}
              />
              <Route
                path="/memegenerator/:orgName"
                element={<MemeGenerator />}
              />
              <Route path="/data" element={<DataDownloadPage />} />
              <Route
                path="/individualSubmission"
                element={<IndividualSubmissionForm />}
              />
              <Route
                path="/individualEdit/:name"
                element={<IndividualEditForm />}
              />
              <Route path="/individual/:name" element={<Individual />} />
              <Route path="/support" element={<SupportUs />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="*" element={<NotFound />} />
              {/* </Route> */}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default RoutesComponent;
