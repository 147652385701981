import React from "react";
import { getIndividualUnderscored } from "../../helpers";
import { useNavigate } from "react-router-dom";
import axios from "../../app/api/axios";
import Button from "@mui/material/Button";

const RANDOM_INDIVIDUAL_URL = "/randomIndividual/";

const RandomOrganizationButton = () => {
  const navigate = useNavigate();

  const getName = async () => {
    const { data } = await axios.get(RANDOM_INDIVIDUAL_URL);
    navigate(getIndividualUnderscored(data[0].name));
  };

  return (
    <Button size="small" variant="contained" onClick={getName}>
      Random Organization
    </Button>
  );
};

export default RandomOrganizationButton;
