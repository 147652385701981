import avatarPlaceholder from "../../res/avatar-placeholder.png";
import star from "../../res/ethnicity_icons/jstar_circle_background.png";
import { Link } from "react-router-dom";
import { getIndividualUnderscored, getBaseUrl } from "../../helpers";
import ScaleText from "react-scale-text";

import "./memePortrait.css";

const INDIVIDUAL_IMAGE_URL = "/individual_images/";

const MemePortrait = ({ individual, fontSize }) => {
  const getEthnicityImage = (ethnicity) => {
    if (ethnicity === "Jewish") {
      return star;
    }
  };

  const stripName = (name) => {
    return name.replace(/ *\([^)]*\) */g, "");
  };

  const getImageSrc = () => {
    if (individual.image_filename) {
      return getBaseUrl() + INDIVIDUAL_IMAGE_URL + individual.image_filename;
    } else if (individual.individual_image_url) {
      return individual.individual_image_url;
    } else {
      return avatarPlaceholder;
    }
  };

  return (
    individual && (
      <Link
        to={getIndividualUnderscored(individual.name)}
        target="_blank"
        rel="noreferrer"
      >
        <div className="meme-portrait-container">
          <img className="meme-portrait" src={getImageSrc()} alt="img" />
          <img
            className="meme-ethnicity-img"
            src={getEthnicityImage(individual.ethnicity)}
            alt="eth"
          />
          <p className="meme-portrait-name-container">
            <ScaleText minFontSize={1} maxFontSize={fontSize}>
              <b className="meme-portrait-name-text">
                {stripName(individual.name)}
              </b>
            </ScaleText>
          </p>
        </div>
      </Link>
    )
  );
};

export default MemePortrait;
