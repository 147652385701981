import { IndividualSummary } from "../";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { carouselTimeout, carouselTransitionTime } from "../../helpers";


const FeaturedIndividuals = () => {
  return (
    <div className="featured-individuals">
      <h1>Featured Individuals</h1>
      <hr />
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={carouselTimeout}
        showThumbs={false}
        transitionTime={carouselTransitionTime}
        showIndicators={false}
      >
        <div className="individuals-summary-container">
          <IndividualSummary name={"Nick Fuentes"} />
          <IndividualSummary name={"Andrew Huberman"} />
          <IndividualSummary name={"Monica Lewinsky"} />
        </div>
        <div className="individuals-summary-container">
          <IndividualSummary name={"Jason Alexander"} />
          <IndividualSummary name={"Chuck Schumer"} />
          <IndividualSummary name={"Marissa Streit"} />
        </div>
        <div className="individuals-summary-container">
          <IndividualSummary name={"Jeffrey Epstein"} />
          <IndividualSummary name={"Jake Tapper"} />
          <IndividualSummary name={"Sean Astin"} />
        </div>
      </Carousel>
    </div>
  );
};

export default FeaturedIndividuals;
