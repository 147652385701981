import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import useImage from "../../hooks/useImage";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import {
  getOrganizationMemeTagline,
  getOrganizationLogo,
  getOrganizationUnderscored,
  chartColors,
} from "../../helpers";
import { MemePortrait } from "../";
import axios from "../../app/api/axios";

import "./memeGenerator.css";

const SUMMARY_BY_ORG_URL = "/allindividualsbyorg/";
const ORG_REPRESENTATION_URL = "/orgEthnicRepresentation/";

const MemeGenerator = () => {
  const memeLogoClass = "meme-logo-image";

  // Grid Constants
  const columnSpacing = 1;
  const nameSliceIndex = 5; // Defines how many grid element will appear on the first row of the meme.

  const numColumns1 = 9;
  const numColumns2 = 10;
  const numColumns3 = 12;
  const numColumns4 = 16;

  const [pieData, setPieData] = useState(null);
  const { orgName } = useParams();
  const orgNameSpaces = orgName.replace(/_/g, " ");

  const [nameList, setNameList] = useState(null);

  const options = {
    backgroundColor: "black",
    pieSliceTextStyle: {
      color: "#ffffff",
      fontSize: 20,
    },
    pieSliceText: "label", // Show labels inside pie slices
    legend: "none", // Remove the legend entirely
    chartArea: {
      width: "85%", // Adjust to take full width of the container
      height: "85%",
    },
    colors: [
      chartColors.Jewish,
      chartColors.White,
      chartColors.Black,
      chartColors.MENA,
      chartColors.Hispanic,
      chartColors.EastAsian,
      chartColors.SoutheastAsian,
      chartColors.SouthAsian,
      chartColors.Other,
      chartColors.Mixed,
      chartColors.Unknown,
    ],
  };

  useEffect(() => {
    const getNameList = async () => {
      const { data } = await axios.get(SUMMARY_BY_ORG_URL + orgName);
      console.log(data);

      setNameList(data);
    };
    getNameList();
  }, [orgName]);

  useEffect(() => {
    let emptyPieData = [
      ["Ethnicity", "Count"],
      ["Jewish", 0],
      ["White", 0],
      ["Black", 0],
      ["MENA", 0],
      ["Hispanic", 0],
      ["East Asian", 0],
      ["Southeast Asian", 0],
      ["South Asian", 0],
      ["Other", 0],
      ["Mixed", 0],
      ["Unknown", 0],
    ];

    let emptyBarData = [
      [
        "Ethnicity",
        "Over / Under Representation ",
        { role: "style" },
        {
          sourceColumn: 0,
          role: "annotation",
          type: "string",
          calc: "stringify",
        },
      ],
      ["Jewish", 0, chartColors.Jewish, null],
      ["White", 0, chartColors.White, null],
      ["Black", 0, chartColors.Black, null],
      ["MENA", 0, chartColors.MENA, null],
      ["Hispanic", 0, chartColors.Hispanic, null],
      ["East Asian", 0, chartColors.EastAsian, null],
      ["Southeast Asian", 0, chartColors.SoutheastAsian, null],
      ["South Asian", 0, chartColors.SouthAsian, null],
    ];

    const getAllIndividuals = async () => {
      const { data } = await axios.get(ORG_REPRESENTATION_URL + orgName);

      let temp = emptyPieData;
      for (let i = 1; i < data.length; i++) {
        for (let j = 1; j < temp.length; j++) {
          if (data[i].name === temp[j][0]) {
            temp[j][1] = data[i].count;
            break;
          }
        }
      }
      setPieData(temp);

      temp = emptyBarData;
      let total = 0;

      for (let i = 1; i < data.length; i++) {
        total += data[i].count;
      }
    };

    getAllIndividuals();
  }, [orgName]);

  const { image } = useImage(orgName + ".png");

  return (
    <div>
      <Link to={getOrganizationUnderscored(orgName)}>
        <button type="button">See Org Details</button>
      </Link>
      {nameList && (
        <Box className="meme-image" sx={{ flexGrow: 1 }}>
          <div className="meme-image-first-row-container">
            <div className="meme-image-logo-container">
              {getOrganizationLogo(orgNameSpaces, memeLogoClass)}
              <h1>{getOrganizationMemeTagline(orgNameSpaces)}</h1>
            </div>

            <Grid
              container
              spacing={{ xs: columnSpacing }}
              columns={{ xs: 5 }}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              {nameList.slice(0, nameSliceIndex).map((item, index) => {
                return (
                  <Grid
                    className="meme-image-first-row"
                    item
                    xs={1}
                    key={index}
                  >
                    <MemePortrait individual={item} fontSize={16} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <Grid
            container
            className="meme-image-second-row"
            spacing={{ xs: columnSpacing }}
            columns={{ xs: numColumns1 }}
          >
            {nameList.slice(5, 23).map((item, index) => {
              return (
                <Grid item xs={1} key={index}>
                  <MemePortrait individual={item} fontSize={16} />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            className="meme-image-third-row"
            spacing={{ xs: columnSpacing }}
            columns={{ xs: numColumns2 }}
          >
            {nameList.slice(23, 33).map((item, index) => {
              return (
                <Grid item xs={1} key={index}>
                  <MemePortrait individual={item} fontSize={16} />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            className="meme-image-fourth-row"
            spacing={{ xs: columnSpacing }}
            columns={{ xs: numColumns3 }}
          >
            {nameList.slice(33, 45).map((item, index) => {
              return (
                <Grid item xs={1} key={index}>
                  <MemePortrait individual={item} fontSize={13} />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            spacing={{ xs: columnSpacing }}
            columns={{ xs: numColumns4 }}
          >
            {nameList.slice(45).map((item, index) => {
              return (
                <Grid className="meme-image-fifth-row" item xs={1} key={index}>
                  <MemePortrait individual={item} fontSize={12} />
                </Grid>
              );
            })}
          </Grid>
          <div className="meme-image-context-box">
            <h3 className="meme-image-context-box-main-text">
              Jewish people make up only 2% of the U.S. population, and 0.2% of the world population, yet they are consistently found to be extremely over-represented in certain walks of life, including but not limited to, the media, politics, finance, certain academic fields and more.
              <br />
              <br />
              What is the cause of this over-representation? Why is this topic, being presented here in black and white statistics, considered so taboo? Should we not be able, as people in Western societies, to discuss this topic openly and honestly? What are your thoughts on this?
              <br />
              <br />
              for more information, and to see a list of all members of this organization (some of which could not fit in this image) visit socialsentinel.net
            </h3>
            <div className="chart-wrapper">
              <Chart
                className="meme-image-chart"
                chartType="PieChart"
                data={pieData}
                options={options}
                height="100%"
              />
            </div>
          </div>
          <h1 style={{ color: "white" }}>socialsentinel.net</h1>
        </Box>
      )}
    </div>
  );
};

export default MemeGenerator;
